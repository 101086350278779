<template>
    <div style="margin-top: 30px; padding: 0 20px;">
        <div class="title">RESPONSIBLE SOCIAL GAMEPLAY POLICY</div>
        <div class="version">Date of Last Update: 12 April 2024</div>
        <div class="content">
            Hit Fortune - Casino Slots is committed to endorsing responsible gameplay as a policy of customer care and social responsibility.
        </div>
        <div class="content">
            We believe it is our responsibility to you, our customers, to ensure that you enjoy your experience on our platforms, while remaining fully aware of the potential risks that can be associated with computer gameplay if you don't remain in control.
        </div>
        <div class="content">
            To ensure that you continue to enjoy safe and manageable play, we fully support responsible gameplay and have put measures in place to assist players who wish to control their play and we reserve the right to activate these measures unilaterally if, in our sole discretion, we consider them necessary.
        </div>
        <div class="title1">
            A. KEEPING TRACK OF YOUR PLAY
        </div>
        <div class="content">
            Playing computer games should be treated as a fun pastime. Here are some tips to help you maintain control of your gameplay:
        </div>
        <div class="content">
            <b>Keep it fun</b>
        </div>
        <div class="content">
            Playing computer games is a form of entertainment, but they should be played in moderation and not to escape reality or to generate income through winning prizes.
        </div>
        <div class="content">
            <b>Don't spend too much</b>
        </div>
        <div class="content">
            Set a budget for your entertainment and stick to it. Only purchase Golden Coins if you can afford to. You can always make use of free play via Hit Fortune - Casino Slots alternative methods of entry (see <span  @click="onClickSwe">SWEEPSTAKES RULES</span>).
        </div>
        <div class="content">
            <b>Keep track of the time</b>
        </div>
        <div class="content">
            We're thrilled you're here and we hope that you can continue playing, but don't let gameplay take up too much of your time. Just like going to the movies, we're here when you need some entertainment.
        </div>
        <div class="content">
            <b>Make use of our gameplay management tools</b>
        </div>
        <div class="content">
            If you need some time out, you can take a break from playing at any time by following the steps in section 2.
        </div>
        <div class="content">
            If you think you may be playing too often, consider the self-assessment questions in section 3 below.
        </div>
        <div class="title1">
            B. STAYING IN CONTROL
        </div>
        <div class="content">
            We encourage our customers to game responsibly, so we offer a variety of responsible gameplay features.
        </div>
        <div class="content">
            <b>Session Reminders</b>
        </div>
        <div class="content">
            <b>Hit Fortune - Casino Slots can assist you to game responsibly by operating a Session Reminder each hour.</b>
        </div>
        <div class="content">
            The Session Reminder:
        </div>
        <div class="content">
            (a). suspends play and indicates how long you have been playing;
        </div>
        <div class="content">
            (b). displays your play history since logging in; and
        </div>
        <div class="content">
            (c). allows you to end the session or continue playing.
        </div>
        <div class="content">
            <b>Set Daily Limits</b>
        </div>
        <div class="content">
            The daily limits function provides you with options to pre-set how many Golden Coins you can purchase each day;
        </div>
        <div class="content">
            To pre-set a limit, you need to contact our support via  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a>. Each day refers to a 24- hour period.
        </div>
        <div class="content">
            We will endeavour to apply your limits as soon as practically possible, however, please note that this process takes a reasonable working period to implement. The limits will not come into effect until they have been fully implemented and this has been communicated to you. If after this you find that the limits have not taken effect, it is your responsibility to inform us immediately.
        </div>
        <div class="content">
            You can change or revoke the limits you have set by contacting our support team via  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a> .
        </div>
        <div class="content">
            <b>Self-Exclusion and Account Closure Options</b>
        </div>
        <div class="content">
            If you want to pre-emptively exclude yourself from the platform so that you are unable to create a Customer Account, you can email your request to  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a>
        </div>
        <div class="content">
            If you feel like you want to take a break, or that your gameplay has become problematic, you may voluntarily exclude yourself from the Hit Fortune - Casino Slots platform by contacting our support team via  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a>. You may request to exclude yourself from the Hit Fortune - Casino Slots platform for a definite period(Definite Self-Exclusion) or for an indefinite period(Indefinite Self-Exclusion).
        </div>
        <div class="content">
            We will endeavour to apply your self-exclusion as soon as practically possible, however, please note that this process takes a reasonable working period to implement. We will not consider the self-exclusion period as having commenced until it has been implemented by us and communicated to you. If after this you find that you can still access the Hit Fortune - Casino Slots platform, it is your responsibility to inform us immediately.
        </div>
        <div class="content">
            During a self-exclusion period, you will not be able to play the Games or make any purchases.
        </div>
        <div class="content">
            At the conclusion of a Definite Self-Exclusion, your access to the Hit Fortune - Casino Slots platform will be reinstated.
        </div>
        <div class="content">
            When an Indefinite Self-Exclusion is applied, it will only be possible to re-open your account by making a written email request to Customer Support via  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a>. The Indefinite Self-Exclusion will only be lifted 3 months after the email request is received by us.
        </div>
        <div class="content">
            Once the self-exclusion is applied, you will no longer receive any further marketing materials from us. We strongly recommend that you also seek exclusion from all other computer gameplay platforms you have an active account with. If you use social media channels, you should take steps to ensure that you don't receive our news or updates.
        </div>
        <div class="content">
            We may exclude you from the Hit Fortune - Casino Slots platform for a definite or indefinite period at our sole discretion if there are reasons to indicate that you may have a computer gameplay problem.
        </div>
        <div class="content">
            <b>Amending or Revoking Limits and Self-Exclusions</b>
        </div>
        <div class="content">
            Where you contact us to change any daily limits you have set:
        </div>
        <div class="content">
            (a). a request for an increase to a limitation will come into effect immediately;
        </div>
        <div class="content">
            (b). a request to decrease or remove a financial limitation will be effective only after the lapse of 5 days from the date we receive the request; and
        </div>
        <div class="content">
            (c). a request to decrease or remove a time limitation will come into effect only after the lapse of 5 days of our receipt of the request.
        </div>
        <div class="content">
            Where you contact us to make changes to a self-exclusion which is in effect:
        </div>
        <div class="content">
            (a). a request for an increase of a self-exclusion will be effective immediately upon our receipt of the request;
        </div>
        <div class="content">
            (b). a request for a decrease of a definite period of self-exclusion will be effective only after the lapse of 5 days from the date we receive the request; and
        </div>
        <div class="content">
            (c). a request to revoke a period of self-exclusion will be effective only after the lapse of 7 days from the date we receive the request to self-exclude for a definite period and after a lapse of three months from the date we receive the request to self-exclude for an indefinite period.
        </div>
        <div class="content">
            <b>Stop playing/Close your account</b>
        </div>
        <div class="content">
            You may choose to close your account. If you wish to do so, make a written email request to Customer Support via  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a>.
        </div>
        <div class="content">
            We reserve the right to refuse or close a Customer Account in our sole discretion, but any contractual obligations already made by us will be honoured accordingly.
        </div>
        <div class="content">
            <b>Exclusion by unregistered players</b>
        </div>
        <div class="content">
            You may also voluntarily exclude yourself from the Hit Fortune - Casino Slots platform if you do not have a customer account. If you wish to do so, you must send an email to Customer Support via  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a> including your full name, full residential address, email address and age to allow us to block you from registering and playing on the Hit Fortune - Casino Slots platform in the future. The self-exclusion will only be lifted 3 months after we receive your written email requesting to ignore your previous request of self-exclusion.
        </div>
        <div class="title1">
            C. SELF-ASSESSMENT
        </div>
        <div class="content">
            If you think your or someone else's computer gameplay is becoming problematic, then it may be handy to consider the self-assessment questions below.
        </div>
        <div class="content">
            (a). Do you often re-live gameplay experiences or think about future ones?
        </div>
        <div class="content">
            (b). Do you hide or lie about your gameplay?
        </div>
        <div class="content">
            (c). Do you get very angry when someone or something interrupts a game?
        </div>
        <div class="content">
            (d). Have you ever taken a break from gameplay and binged uncontrollably upon your return?
        </div>
        <div class="content">
            (e). When upset, do you soothe yourself with games or plans to game?
        </div>
        <div class="content">
            (f). Do you find yourself playing casino or poker games in the early morning?
        </div>
        <div class="content">
            (g). Do you find ways to game when away from home?
        </div>
        <div class="content">
            (h). Do you set limits to your gameplay and then break them, playing hours longer than intended?
        </div>
        <div class="content">
            (i). Do you lose hours of sleep to gameplay?
        </div>
        <div class="content">
            (j). Have you called in sick or late to work or skipped classes to game?
        </div>
        <div class="content">
            (k). Have you sworn offa game, uninstalled it, and later returned to it?
        </div>
        <div class="content">
            (l). Do you feel guilt and shame around your gameplay?
        </div>
        <div class="content">
            (m). Does your gameplay contribute to arguments in your relationships?
        </div>
        <div class="content">
            (n). Has your gameplay taken the place of any hobbies or sports you used to enjoy?
        </div>
        <div class="content">
            (o). Do you forget appointments, responsibilities or deadlines in work or school when playing online?
        </div>
        <div class="content">
            (p). Do you become irritated and defensive when people suggest you might be playing online too much?
        </div>
        <div class="content">
            (q). Have your hours spent playing online increased over time?
        </div>
        <div class="content">
            (r). Do you blow off social events to game?
        </div>
        <div class="content">
            (s). Have you lost contact with friends and family since playing online?
        </div>
        <div class="content">
            (t). Do you have intense feelings (highs, lows, anger, fear) while playing online?
        </div>
        <div class="content">
            The more questions that you answer "yes" to, the more likely it is that you are having difficulties with your gameplay. To speak with someone who can give you advice and support, please contact the Support Organization referred to in 4.
        </div>
        <div class="title1">
            D. SUPPORT ORGANISATION
        </div>
        <div class="content">
            Should you wish to access help and support services for people who have been adversely affected by gameplay, we advise you to get in touch with the Computer Gaming Addicts Anonymous (CGAA) using the following email address: <a href="http://helpline@cgaa.info" target="_blank">helpline@cgaa.info</a>.
        </div>
        <div class="content">
            Important: Please note that the CGAA is an independent problem gameplay support service and is NOT in any way affiliated with Hit Fortune - Casino Slots. The CGAA does NOT provide customer support or dispute resolution services. Should you wish to discuss any matter or complaint related to your account, you can do so by contacting us via  <a href="mailto:admin@gamewonder.vip" target="_blank">admin@gamewonder.vip</a>.
        </div>
        <div class="title2">
            PLAYER PROTECTION POLICY
        </div>
        <div class="content">
            <b>A. PROTECTION OF MINORS</b>
        </div>
        <div class="content">
            With the internet accessible via mobile devices and in homes around the world, responsible online gameplay relies heavily on parental control and supervision. In order to ensure child safety on the internet, we encourage our customers to make use of filtering software to prevent minors from accessing inappropriate online material.
        </div>
        <div class="content">
            <b>B. TIPS FOR PARENTS</b>
        </div>
        <div class="content">
            We offer the following tips for parents:  
        </div>
        <div class="content">
            (a). Keep your username and password safe.
        </div>
        <div class="content">
            (b). Do not leave your computer or device unattended when logged in to your account.
        </div>
        <div class="content">
            (c). Do not use the "Remember Me" feature on a shared computer or device, especially if you share yourcomputer or device with children.
        </div>
        <div class="content">
            (d). Take particular care regarding the use by minors of all electronic and mobile devices.
        </div>
        <div class="content">
            (e). Keep your bank cards and bank account details out of reach of children.
        </div>
        <div class="content">
            (f). Do not allow minors (under 18 years) to participate in any gameplay activity meant for adults (+18 years).
        </div>
        <div class="content">
            (g). Make use of filtering software to prevent your children from accessing inappropriate material online.
        </div>
        <div class="content">
            2.2.If you share your device or computer with friends or family who are under the legal age to participate in online social gameplay, we recommend that you restrict their access to gameplay platforms. There are third-party services available that may be able to assist with this, such as:
        </div>
        <div class="content">
            (a). <a href="http://Netnanny.com" target="_blank">Netnanny.com</a>
        </div>
        <div class="content">
            (b). <a href="http://Cybersitter.com" target="_blank">Cybersitter.com</a>
        </div>
        <div class="content">
            (c). <a href="http://Cyberpatrol.com" target="_blank">Cyberpatrol.com</a>
        </div>
        <div class="content">
            <b>C. TIPS FOR PLAYERS</b>
        </div>
        <div class="content">
            Make sure that the decision to play Hit Fortune - Casino Slots is your own personal choice. 3.2. Determine how much you will spend before playing.
        </div>
        <div class="content">
            Before you start playing, familiarise yourself with the rules of the games.
        </div>
        <div class="content">
            We suggest that you don't play on our platform if:
        </div>
        <div class="content">
            (a). it interferes with your daily responsibilities;
        </div>
        <div class="content">
            (b). you are being treated or are in recovery for any type of dependency;
        </div>
        <div class="content">
            (c). you are under the influence of alcohol or any other substance;
        </div>
        <div class="content">
            (d). your primarypurpose is to pay debts using prizes you might win
        </div>
    </div>
</template>
<script>
export default {
    name:"TermsOfUseView",
    title: "Terms of Use",
    methods: {
        onClickSwe: function(){
      this.$router.push("Sweepstakes")
    },
    }
}
</script>
<style lang="scss" scoped>
*{
    font-size: 18px;
}
.title {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 23px
}

.title2 {
    display: block;
    text-align: left;
    font-weight: bold;
    font-size: 21px;
}

.title1 {
    display: block;
    text-align: left;
    font-weight: bold;
}

.version {
    display: block;
    text-align: left;
}

.content {
    display: block;
    text-align: left;
}

.content_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

p {
    font-style: italic;
}

span {
    color: #245bdb;
    text-decoration: underline;
    cursor: pointer;
}

div {
    margin: 12px;
}
</style>